const actions = {
  setVehicleFilter: ({ commit }, payload) => {
    commit('SET_VEHICLE_FILTER', payload);
  },
  setSearchQuery: ({ commit }, payload) => {
    commit('SET_SEARCH_QUERY', payload);
  },
  clearFilterAndSearch: ({ commit }, payload) => {
    commit('CLEAR_FILTER_AND_SEARCH', payload);
  },
  setTotalVehicles: ({ commit }, payload) => {
    commit('SET_TOTAL_VEHICLES', payload);
  },
  setTotalFilteredVehicles: ({ commit }, payload) => {
    commit('SET_TOTAL_FILTERED_VEHICLES', payload);
  },
  toggleVehicleFilter: ({ commit }, payload) => {
    commit('TOGGLE_VEHICLE_FILTER', payload);
  },
  setPaginationQuery({ commit }, payload) {
    commit('SET_PAGINATION_QUERY', payload);
  },
  setSkusByStatusFilter({ commit }, payload) {
    commit('SET_SKUS_BY_STATUS_FILTER', payload);
  },
  addSelected: ({ commit }, payload) => {
    let newSelected = {};

    payload.data.forEach((vehicle) => {
      newSelected[vehicle[payload.key]] = {
        id: vehicle.vehicleId,
        vin:
          vehicle.displayName !== undefined &&
          vehicle.displayName !== '' &&
          vehicle.displayName !== null
            ? vehicle.displayName
            : vehicle.name !== undefined &&
              vehicle.name !== '' &&
              vehicle.name !== null
            ? vehicle.name
            : vehicle.vin,
        vinNumber: vehicle.vin,
        consentedConsumers:
          vehicle.consentedConsumers !== undefined
            ? vehicle.consentedConsumers
            : [],
        enrollmentStatus: vehicle.enrollmentStatus,
      };
    });

    commit('ADD_SELECTED', { data: newSelected });
  },
  removeSelected: ({ commit }, payload) => {
    commit('REMOVE_SELECTED', payload);
  },
  clearSelected: ({ commit }, payload) => {
    commit('CLEAR_SELECTED', payload);
  },
  setInitialSelected: ({ commit }) => {
    commit('SET_INITIAL_SELECTED');
  },
  clearInitialSelected: ({ commit }) => {
    commit('CLEAR_INITIAL_SELECTED');
  },
  resetSelected: ({ commit }) => {
    commit('RESET_SELECTED');
  },
  refreshTable: ({ commit }) => {
    commit('REFRESH_TABLE');
  },
  setVehicleGridColumns: ({ commit }, payload) => {
    commit('SET_VEHICLE_GRID_COLUMNS', payload);
  },
  setOwnerVehicleGridColumns: ({ commit }, payload) => {
    commit('SET_OWNER_VEHICLE_GRID_COLUMNS', payload);
  },
  setFilteredVehicleSecurityCapabilitiesGridColumns: ({ commit }, payload) => {
    commit('SET_VEHICLE_SECURITY_CAPABILITIES_GRID_COLUMNS', payload);
  },
  setPrimitivesInVehicleFilter: ({ commit }, payload) => {
    commit('SET_PRIMITIVES_IN_VEHICLE_FILTER', payload);
  },
  resetState: ({ commit }) => {
    commit('RESET_STATE');
  },
  setCcsSettings: ({ commit }, payload) => {
    commit('SET_CCS_SETTING', payload);
  },
};

export { actions };
