import { isEqual } from 'lodash';

const getters = {
  vehicleFilter: (state) => {
    return state.vehicleFilter;
  },
  skusByStatusFilter: (state) => {
    return state.skusByStatusFilter;
  },
  areFiltersOrSearchApplied: (state) => {
    let keys = Object.keys(state.vehicleFilter);
    return (
      keys.length !== 0 ||
      (state.searchQuery !== '' && state.searchQuery !== null)
    );
  },
  areFiltersOrSearchAppliedExceptCustom: (state) => {
    let keys = Object.keys(state.vehicleFilter);
    keys = keys.filter((key) => {
      return !(key === 'vehicleList' || key === 'externalFilterDisplay');
    });
    return (
      keys.length !== 0 ||
      (state.searchQuery !== '' && state.searchQuery !== null)
    );
  },
  showAllNonConsented: (state) => {
    return state.vehicleFilter.showAllNonConsented;
  },
  showAllConsented: (state) => {
    return state.vehicleFilter.showAllConsented;
  },
  paginationQuery: (state) => {
    return state.paginationQuery;
  },
  searchQuery: (state) => {
    return state.searchQuery;
  },
  selected: (state) => {
    return state.selected;
  },
  totalVehicles: (state) => {
    return state.totalVehicles;
  },
  totalFilteredVehicles: (state) => {
    return state.totalFilteredVehicles;
  },
  isSelected: (state) => (id) => {
    return state.selected[id] !== undefined;
  },
  selectedLength: (state) => {
    return Object.keys(state.selected).length;
  },
  isSelectedModified: (state) => {
    return !isEqual(
      Object.keys(state.selected).sort(),
      Object.keys(state.initialSelected).sort()
    );
  },
  vehicleGridColumns: (state) => {
    return state.vehicleGridColumns;
  },
  filteredVehicleSecurityCapabilitiesGridColumns: (state) => {
    return state.filteredVehicleSecurityCapabilitiesGridColumns;
  },
  ownerVehicleGridColumns: (state) => {
    return state.ownerVehicleGridColumns;
  },
  hasColumn: (state) => (column) => {
    return state.vehicleGridColumns.map((v) => v.code).includes(column);
  },
  hasOwnerVehicleColumn: (state) => (column) => {
    return state.ownerVehicleGridColumns.map((v) => v.code).includes(column);
  },
  hasExternalFilterDisplay: (state) => {
    return (
      state.vehicleFilter.externalFilterDisplay != null &&
      state.vehicleFilter.externalFilterDisplay.length >= 1
    );
  },
  getCcsSettings: (state) => {
    return state.ccsSettings;
  },
};

export { getters };
