import { render, staticRenderFns } from "./ProductAddUpgradeConfirmation.vue?vue&type=template&id=01499f96&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import script from "./ProductAddUpgradeConfirmation.vue?vue&type=script&lang=js&"
export * from "./ProductAddUpgradeConfirmation.vue?vue&type=script&lang=js&"
import style0 from "./ProductAddUpgradeConfirmation.vue?vue&type=style&index=0&id=01499f96&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01499f96",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCardText,VCheckbox,VDivider,VFlex,VIcon,VLayout,VSheet})
