import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { make } from 'vuex-pathify';
import { state as vehicle } from '@/store/vehicle';

const mutations = {
  ...make.mutations(vehicle),
  SET_VEHICLE_FILTER: (state, payload) => {
    if (payload.selected.length === 0) {
      Vue.delete(state.vehicleFilter, payload.name);
    } else {
      Vue.set(state.vehicleFilter, payload.name, payload.selected);
    }
  },
  SET_SEARCH_QUERY: (state, payload) => {
    Vue.set(state, 'searchQuery', payload.data);
  },
  CLEAR_FILTER_AND_SEARCH: (state, payload) => {
    Vue.set(state, 'searchQuery', '');
    Vue.set(state, 'vehicleFilter', payload ? { ...payload } : {});
  },
  SET_TOTAL_VEHICLES: (state, payload) => {
    Vue.set(state, 'totalVehicles', payload);
  },
  SET_TOTAL_FILTERED_VEHICLES: (state, payload) => {
    Vue.set(state, 'totalFilteredVehicles', payload);
  },
  TOGGLE_VEHICLE_FILTER: (state, payload) => {
    let vehicleFilter = state.vehicleFilter[payload.name];
    if (vehicleFilter) {
      Vue.delete(state.vehicleFilter, payload.name);
    } else {
      Vue.set(state.vehicleFilter, payload.name, true);
    }
  },
  ADD_SELECTED: (state, payload) => {
    for (let vehicleId of Object.keys(payload.data)) {
      Vue.set(state.selected, vehicleId, payload.data[vehicleId]);
    }
  },
  REMOVE_SELECTED: (state, payload) => {
    for (let vehicleId of Object.keys(payload.data)) {
      Vue.delete(state.selected, payload.data[vehicleId]);
    }
  },
  CLEAR_SELECTED: (state) => {
    state.selected = {};
  },
  SET_PAGINATION_QUERY: (state, payload) => {
    Vue.set(state, 'paginationQuery', payload.data);
  },
  SET_INITIAL_SELECTED: (state) => {
    state.initialSelected = cloneDeep(state.selected);
  },
  CLEAR_INITIAL_SELECTED: (state) => {
    state.initialSelected = {};
  },
  RESET_SELECTED: (state) => {
    state.selected = cloneDeep(state.initialSelected);
  },
  REFRESH_TABLE: (state) => {
    return state;
  },
  SET_VEHICLE_GRID_COLUMNS: (state, payload) => {
    state.vehicleGridColumns = payload;
  },
  SET_VEHICLE_SECURITY_CAPABILITIES_GRID_COLUMNS: (state, payload) => {
    state.filteredVehicleSecurityCapabilitiesGridColumns = payload;
  },
  SET_OWNER_VEHICLE_GRID_COLUMNS: (state, payload) => {
    state.ownerVehicleGridColumns = payload;
  },
  SET_PRIMITIVES_IN_VEHICLE_FILTER: (state, payload) => {
    Vue.set(state.vehicleFilter, payload.name, payload.selected);
  },
  SET_SKUS_BY_STATUS_FILTER: (state, payload) => {
    Vue.set(state, 'skusByStatusFilter', payload);
  },
  RESET_STATE: (state) => {
    state.vehicleFilter = {};
    state.totalVehicles = 0;
    state.totalFilteredVehicles = 0;
    state.searchQuery = '';
    state.paginationQuery = {};
    state.selected = {};
    state.initialSelected = {};
    state.vehicleGridColumns = [];
    state.ownerVehicleGridColumns = [];
  },
  SET_CCS_SETTING: (state, payload) => {
    Vue.set(state, 'ccsSettings', payload);
  },
};

export { mutations };
