<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <TemplateApp :sidebar-items="sidebarItems">
    <template v-slot:header>
      <GlobalTopBar
        v-if="getIsLoggedIn || isRegistrationForm"
        data-qa="top-bar"
      ></GlobalTopBar>
    </template>
    <template v-slot:footer>
      <TheFooter v-if="getIsLoggedIn"></TheFooter>
    </template>
    <template v-slot:dialogs="{ canUseFFMApplication }">
      <FordProGatewayRedirectDialog />
      <TheCookieBanner
        v-if="
          getIsLoggedIn &&
          canUseFFMApplication &&
          isOneTrustEnabled &&
          !isCookieBannerInteracted
        "
      />
    </template>
  </TemplateApp>
</template>

<script>
import { mapGetters } from 'vuex';
import GlobalTopBar from '@/components/TheGlobalTopBar';
import CookieUtils from '@/services/CookieUtil';
import TheCookieBanner from '@/components/cookie-management/TheCookieBanner';
import { AccountConstants } from '@/constants';
import TheFooter from '@/components/TheFooter';
import TemplateApp from '@/TemplateApp';
import FordProGatewayRedirectDialog from '@/components/FordProGatewayRedirectDialog';

export default {
  name: 'App',
  components: {
    GlobalTopBar,
    FordProGatewayRedirectDialog,
    TheCookieBanner,
    TheFooter,
    TemplateApp,
  },
  data() {
    return {
      isCookieBannerInteracted: CookieUtils.cookieInteractedExists(),
      isOneTrustEnabled: AccountConstants.ONE_TRUST.ENABLE,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'canViewProducts',
      'canViewVehicles',
      'canViewUsers',
      'canViewGroups',
      'canViewBilling',
      'canViewVehicleConsent',
      'canViewEquipment',
      'getIsLoggedIn',
    ]),
    ...mapGetters('fpgConfiguration', ['isTileMode']),
    isRegistrationForm() {
      return (
        this.$route.path === '/customer-signup' ||
        this.$route.path === '/third-party-signup'
      );
    },
    sidebarItems() {
      return [
        {
          title: this.$t('sidebar.products'),
          icon: 'ic-products',
          path: '/',
          userVisible: this.canViewProducts(),
        },
        {
          title: this.$t('sidebar.vehicles'),
          icon: 'ic-vehicles',
          path: '/vehicles',
          group: 'vehicleGroup',
          groupHead: true,
          userVisible: this.canViewVehicles(),
        },
        {
          title: this.$t('sidebar.equipment'),
          icon: 'ic-equipment',
          path: '/equipment',
          userVisible: this.canViewEquipment(),
        },
        {
          title: this.$t('sidebar.consent'),
          icon: 'ic-vehicles',
          path: '/consent',
          group: 'consentGroup',
          groupHead: true,
          userVisible: this.canViewVehicleConsent(),
        },
        {
          title: this.$t('sidebar.users'),
          icon: 'ic-users',
          path: '/users',
          userVisible: this.canViewUsers(),
        },
        {
          title: this.$t('sidebar.groups'),
          icon: 'ic-groups',
          path: '/groups',
          userVisible: this.canViewGroups(),
        },
        {
          title: this.$t('sidebar.billing'),
          icon: 'ic-billing',
          path: '/billing',
          userVisible: this.canViewBilling(),
        },
      ];
    },
  },
};
</script>
